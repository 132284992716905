<template>
    <div>
        <Head :title="$t('update')"/>

        <Toolbar class="mb-4 !border-none">
            <template #start id="tollbar-start">
                <div class="font-medium text-xl">{{ user.username }}</div>
            </template>

            <template #end>
                <Button :label="$t('save')" @click.prevent="form.post(formUrl)"></Button>
            </template>
        </Toolbar>

        <Fluid>
            <div class="flex flex-col md:flex-row gap-4">
                <div class="card flex flex-col w-full md:w-9/12 mb-8">
                    <div class="grid grid-flow-row md:grid-cols-2 gap-y-7 gap-x-4">
                        <div class="gap-2 w-full">
                            <TextInput :label="$t('user.username')" v-model="form.username" :errors="errors.username" />
                        </div>
                        <div class="gap-2 w-full">
                            <TextInput :label="$t('user.email')" v-model="form.email" :errors="errors.email" />
                        </div>
                        <div class="gap-2 w-full">
                            <TextInput :label="$t('user.firstname')" v-model="form.firstname" :errors="errors.firstname" />
                        </div>
                        <div class="gap-2 w-full">
                            <TextInput :label="$t('user.lastname')" v-model="form.lastname" :errors="errors.lastname" />
                        </div>
                        <div class="gap-2 w-full">
                            <PhoneInput v-model="form.phone" :label="$t('user.phone')" :errors="errors.phone" autocomplete="off"></PhoneInput>
                        </div>
                        <div class="gap-2 w-full">
                            <TextInput label="Facebook" v-model="form.facebook" :errors="errors.facebook" />
                        </div>
                    </div>

                    <h5 class="mb-8">{{ $t('user.block_password') }}</h5>
                    <div class="grid grid-flow-row md:grid-cols-2 gap-y-7 gap-x-4">
                        <div class="gap-2 w-full">
                            <TextInput :label="$t('user.password')" type="password" v-model="form.password" :errors="errors.password" />
                        </div>
                        <div class="gap-2 w-full">
                            <TextInput :label="$t('user.password_confirmation')" type="password" v-model="form.password_confirmation" :errors="errors.password_confirmation" />
                        </div>
                        <div class="gap-2 w-full col-span-2">
                            <Textarea :value="telegram_key" rows="3" readonly/>
                        </div>
                    </div>
                </div>
                <div class="card flex flex-col w-full md:w-3/12 last:mb-8">
                    <div v-if="!avatarEditMode" class="w-full max-w-80 m-auto">
                        <div>
                            <a @click="triggerFileSelection();" href="javascript:;" class="avatar-link" >
                                <Avatar :image="form.avatar ?? '/img/team-2.jpg'" class="user-avatar" :pt="{ image: { class: 'rounded' } }" />
                                <div class="avatar-hover"><i class="far fa-edit"></i></div>
                            </a>
                        </div>
                    </div>
                    <div v-show="uploadedImage && avatarEditMode" class="w-full max-w-80 m-auto">
                        <div class="hidden">
                            <input type="file" ref="fileInput" @input="onFileChange($event)" id="avatar" :class="[(errors.avatar ? 'is-invalid' : '')]" name="avatar"/>
                        </div>
                        <div>
                            <cropper
                                class="cropper"
                                ref="cropper"
                                :src="uploadedImage"
                                :stencil-component="$options.components.CropperStencil"
                                :stencil-props="{
                                    aspectRatio: 1/1,
                                }"
                            ></cropper>
                            <div class="flex mt-4">
                                <Button severity="info" @click="crop" class="me-4">{{ $t('save') }}</Button>
                                <Button severity="danger" @click="croppedImage = null; form.avatar = avatar_old; uploadedImage = null; avatarEditMode = false;">
                                    {{ $t('cancel_button') }}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fluid>
    </div>
</template>


<script>
import { useForm, Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import PrimeLayout from '../../Layouts/PrimeLayout.vue';
import Alert from '../../Components/Alert.vue';
import FileInput from '../../Components/Form/FileInput.vue';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { resizeDataUrlImage } from '../../Composables/imageResize';
import CropperStencil from '../../Components/CropperStencil.vue';
import { TextInput, PhoneInput } from "../../Components/PrimeForm";

export default {
    layout: PrimeLayout,
    components: {
        Head,
        Alert,
        FileInput,
        Cropper,
        CircleStencil,
        CropperStencil,
        TextInput,
        PhoneInput
    },
    props: {
        errors: Object,
        user: Object,
        telegram_key: String
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            id: props.user.id,
            username: props.user.username,
            firstname: props.user.firstname,
            lastname: props.user.lastname,
            email: props.user.email,
            phone: props.user.phone,
            facebook: props.user.facebook,
            avatar:  props.user.avatar,
            password: '',
            password_confirmation: '',
            _method: 'PUT' //При работе с файлами без этого не обновит
        });

        return { form, hasRole, hasPermission };
    },
    data() {
        return {
            currentUserId: this.$page.props.auth.user.id,
            avatarEditMode: false,
            croppedImage: null,
            uploadedImage: null,
        };
    },
    computed:{
        formUrl(){
            return this.currentUserId === this.user.id ? route('profile.update') : route('user.update', this.user.id) ;
        }
    },
    methods:
    {
        async crop() {
			const { coordinates, canvas } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;

			if (canvas) {
				this.croppedImage = canvas.toDataURL(); 
                this.form.avatar = await resizeDataUrlImage({
					dataUrl: canvas.toDataURL(),
					width: 250,
					height: 250,
                });
                this.avatarEditMode = false;
			};
		},
        onFileChange(event) {
            const input = event.target;
            this.uploadedImage = null;
            if (input.files && input.files[0]) {
				const reader = new FileReader();
				reader.onload = (e) => { this.uploadedImage = e.target.result; this.avatarEditMode = true;};
                reader.readAsDataURL(input.files[0]);
			}
        },
        triggerFileSelection()
        {
            this.$refs.fileInput.click();
        }
    }
}
</script>

<style scoped>
.user-avatar{
    width:100% !important;
    height:100% !important;
}
.avatar-link{
    transition: all 0.5s;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.avatar-hover{
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    height: 98%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.avatar-link:hover .avatar-hover{
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
}

.avatar-hover i{
    display: block;
    margin: auto;
    color: #fff;
    font-size: 1.5rem;
}
</style>