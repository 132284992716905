<template>
    <div class="h-full">
        <Head :title="$t('Leads')"/>
        <ConfirmPopup></ConfirmPopup>

        <Toolbar class="mb-4 !border-none">
            <template #start id="tollbar-start">
                <div class="font-medium text-xl">{{ $t('Leads') }}</div>
                <Button icon="pi pi-search" severity="secondary" class="md:!hidden" text rounded @click="searchDrawer = !searchDrawer"/>
            </template>

            <template #center>
                <IconField class="!hidden md:!block">
                    <InputIcon>
                        <i class="pi pi-search" />
                    </InputIcon>
                    <InputText :placeholder="$t('search')" v-model="filterData.search"/>
                </IconField>
            </template>

            <template #end>
                <Button 
                    as="a" 
                    :href="route('lead.export')" 
                    class="me-2" 
                    severity="secondary"  
                    v-tooltip.top="$t('export_excel')" 
                    icon="far fa-file-excel" 
                    v-if="hasRole('Manager') || hasRole('Admin')"
                    outlined
                />
                <Button v-if="hasRole('Manager') || hasRole('Admin')" icon="pi pi-filter" severity="secondary" class="me-2" @click="filterDrawer = !filterDrawer" outlined/>
                <Button as="router-link" :label="$t('create')" :to="route('lead.create')"></Button>
            </template>
        </Toolbar>

        <Menu :model="filteredStages" ref="stages_popover" :popup="true">
            <template #item="{ item, props }">
                <div class="flex flex-row items-center justify-center py-2" @click.prevent.stop="activeColumn = item.sid; $refs.stages_popover.hide();">
                    <span class="font-medium">{{ item.title }}</span>
                    <span class="flex items-center justify-center w-5 h-5 ml-2 text-sm font-semibold rounded" :class="item.background+' '+item.text">{{ columns[item.sid]?.length ?? 0 }}</span>
                </div>
            </template>
        </Menu>

        <Menu :model="filteredStages" ref="item_menu" :popup="true" @blur="resetItemMenu">
            <template #item="{ item, props }">
                <div class="p-2" @click.prevent.stop="setStage(item.sid)">
                    <span class="font-medium">{{ item.title }}</span>
                </div>
            </template>
        </Menu>

        <div class="flex flex-grow mt-4 md:space-x-6 overflow-x-hidden md:overflow-auto" style="height: calc(100% - 4rem)">
            <template v-for="(stage, key) in stages" :key="'column_'+key">
                <div class="hidden md:flex flex-col flex-shrink-0 order-last" v-if="!mobile && stage.hidden">
                    <div class="flex items-center flex-shrink-0 h-10 px-2 rounded justify-between md:justify-start" :class="stage.background+' '+stage.text" @click="popoverStages">
                        <span class="material-symbols-outlined text-base hover:cursor-pointer hidden md:block" @click.prevent.stop="toggleVisibility(key)" translate="no">visibility</span>
                    </div>
                </div>
                <template v-else>
                    <div class="flex flex-col flex-shrink-0 md:w-[350px] w-full" v-if="!mobile || key == activeColumn">
                        <div class="flex items-center flex-shrink-0 h-10 px-2 rounded justify-between md:justify-start" :class="stage.background+' '+stage.text" @click="popoverStages">
                            <div class="flex flex-row items-center">
                                <span class="block text-base font-semibold">{{ stage.title }}</span>
                                <span class="flex items-center justify-center w-5 h-5 ml-2 text-sm font-semibold text-white bg-white rounded bg-opacity-30">{{ columns[key]?.length ?? 0 }}</span>
                            </div>
                            <span class="material-symbols-outlined ms-auto text-base hover:cursor-pointer hidden md:block" @click.prevent.stop="toggleVisibility(key)" translate="no">visibility_off</span>
                        </div>
                        <VueDraggable
                            :disabled="mobile"
                            :data-cid="key"
                            class="flex flex-col pb-2 overflow-auto md:h-[80dvh]"
                            :sort="false"
                            :filter="'.non-draggable'"
                            v-model="columns[key]"
                            group="kanban"
                            @add="changeStage"
                        >
                            <KanbanItem :lead="lead" :non-draggable="hasRole('Recruiter') && key == 4" :errors="leadErrors[lead.id]" v-for="(lead, index) in columns[key]" :key="lead.id" :stages="stages" :activeStage="activeColumn" @next-stage="setItemMenu($event, index, lead, key)"/>
                        </VueDraggable>
                    </div>
                </template>
            </template>
            <div class="hidden md:flex flex-shrink-0 w-6"></div>
        </div>

        <LeadFilter 
            v-model:active="filterDrawer" 
            @filter="onFilter"
            v-model:filterData="filterData"
            :responsibles="responsibles"
        />
        <Drawer v-model:visible="searchDrawer" position="top" style="height: auto">
            <template #container="{ closeCallback }">
                <div class="flex flex-row items-center gap-1 p-4">
                    <div class="grow">
                        <InputText class="!border-none !shadow-none" :placeholder="$t('search')" v-model="drawerSearchValue" fluid size="large" @keyup.enter="mobileSearchHandle(closeCallback)"/>
                    </div>
                    <div>
                        <Button v-if="drawerSearchValue" type="button" severity="secondary" @click="mobileSearchClear(closeCallback)" icon="pi pi-times" text size="large"></Button>
                        <Button type="button" severity="secondary" @click="mobileSearchHandle(closeCallback)" icon="pi pi-search" text size="large"></Button>
                    </div>
                </div>
            </template>
        </Drawer>
    </div>
</template>



<script>
import { ref } from 'vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { Link, Head, router } from "@inertiajs/vue3";
import { usePermissions } from '../../Composables/usePermissions';
import PrimeLayout from '../../Layouts/PrimeFullHeightLayout.vue';
import KanbanItem from '../../Components/KanbanItem.vue';
import { VueDraggable } from 'vue-draggable-plus';
import LeadFilter from '../../Components/LeadFilter.vue';

export default {
    layout: PrimeLayout,
    components: { VueDraggable, KanbanItem, Head, LeadFilter },
    props: {
        //stages: Object,
        filter: Object,
        leads: Object,
        responsibles: Object
    },
    setup(props) {
        const filterData = ref({
            search: props.filter?.search ?? null,
            responsibles: props.filter?.responsibles ?? null,
        });

        //TODO:Сделать притяжку стадий из 1 места
        let lsHidden = [];
        if (localStorage.hiddenKanbanColumns)
            lsHidden = JSON.parse(localStorage.hiddenKanbanColumns);

        const stages = ref([
            {
                title: 'Новый',
                text: 'text-white',
                background: 'bg-slate-500',
                sid: 0,
                hidden: lsHidden.includes(0)
            },
            {
                title: 'В работе',
                text: 'text-white',
                background: 'bg-blue-500',
                sid: 1,
                hidden: lsHidden.includes(1)
            },
            {
                title: 'Подтверждение',
                text: 'text-white',
                background: 'bg-orange-500',
                sid: 2,
                hidden: lsHidden.includes(2)
            },
            {
                title: 'Согласен',
                text: 'text-white',
                background: 'bg-purple-500',
                sid: 3,
                hidden: lsHidden.includes(3)
            },
            {
                title: 'Подан',
                text: 'text-white',
                background: 'bg-green-500',
                sid: 4,
                hidden: lsHidden.includes(4)
            },
            {
                title: 'Ожидание',
                text: 'text-white',
                background: 'bg-black',
                sid: 5,
                hidden: lsHidden.includes(5)
            },
            {
                title: 'Отказ',
                text: 'text-white',
                background: 'bg-red-500',
                sid: 6,
                hidden: lsHidden.includes(6)
            },
        ]);

        function initColumns() {
            let columns = [];
            stages.value.forEach((stage, sid) => {
                if (props.leads[sid])
                    columns[sid] = _.cloneDeep(props.leads[sid]);
                else
                    columns[sid] = [];
            });

            return columns;
        }

        let columns = ref(initColumns());

        const { hasRole, hasPermission } = usePermissions();

        const breakpoints = useBreakpoints(breakpointsTailwind);
        const mobile = breakpoints.smallerOrEqual('md');

        return { stages, hasPermission, hasRole, mobile, columns, filterData, initColumns };
    },
    data() {
        return {
            activeColumn: 1,
            activeItem: null,
            search: null,
            leadErrors: [],
            loading: false,
            filterDrawer: false,
            searchDrawer: false,
            drawerSearchValue: this.filterData.search
        };
    },
    methods: {
        fetch(filter = null) {
            if (filter) {
                this.filterData = filter;
            }
            
            router.get(
                route('lead.index'), 
                {
                    filter: this.makeFilterQuery(this.filterData)
                }, 
                { 
                    preserveScroll: true, 
                    preserveState: true,
                    onBefore: (visit) => {this.loading = true;},
                    onFinish: (visit) => { this.columns = this.initColumns(); this.loading = false; }
                }
            );
        },
        toggleVisibility(column) {
            this.stages[column].hidden = !this.stages[column].hidden;
            let lsHidden = null;
            if (localStorage.hiddenKanbanColumns)
                lsHidden = JSON.parse(localStorage.hiddenKanbanColumns);
            //if hide column
            if (this.stages[column].hidden) {
                if (lsHidden && Array.isArray(lsHidden))
                    lsHidden.push(column);
                else
                    lsHidden = [column];
            }
            else {
                if (lsHidden && Array.isArray(lsHidden)) {
                    lsHidden.splice(lsHidden.indexOf(column), 1);
                }
            }
            localStorage.hiddenKanbanColumns = JSON.stringify(lsHidden);
        },
        makeFilterQuery(filterValues){
            let query = {};
            Object.keys(filterValues).forEach((key)=>{
                const value = filterValues[key];
                if (value !== null && value !== undefined){
                    query[key] = filterValues[key];
                }
            });
            
            return query;
        },
        popoverStages(event) {
            if (this.mobile)
                this.$refs.stages_popover.toggle(event);
        },
        changeStage(event) {
            this.leadErrors = [];
            const newStage = event.to.getAttribute('data-cid');
            const newIndex = event.newIndex;
            const lead = event.data;

            //Если рекрутер тянет в "срекрутирован"
            if (this.hasRole('Recruiter') && newStage == 4) {
                this.$nextTick(() => {
                    this.cancelMove(event);
                });
                return;
            }

            axios.post(route('lead.change_stage', { lead: lead }), {
                stage_id: newStage
            }).then(response => { 
            }).catch(error => {
                const response = error.response?.data;
                if (response?.errors) {
                    this.leadErrors[lead.id] = response.errors;
                }
                else {
                    console.error(error);
                }
                const item = this.cancelMove(event);
            });
        },
        setItemMenu(event, index, lead, sid) {
            this.activeItem = {
                index: index,
                lead: lead,
                sid: sid
            };
            this.$refs.item_menu.toggle(event);
        },
        resetItemMenu() {
            this.activeLead = null;
            console.log('reset');
        },
        setStage(newStage) {
            this.leadErrors = [];
            const lead = this.activeItem.lead;
            const sid = this.activeItem.sid;
            const index = this.activeItem.index;

            const oldStage = sid;
            //const newStage = sid + 1;
            if (this.stages.length > newStage) {
                axios.post(route('lead.change_stage', { lead: lead }), {
                    stage_id: newStage
                }).then(response => {
                }).catch(error => {
                    const response = error.response?.data;
                    if (response?.errors) {
                        this.leadErrors[lead.id] = response.errors;
                    }
                    else {
                        console.error(error);
                    }
                }).finally(() => {
                    this.$refs.item_menu.hide();
                });
            }
            
        },
        cancelMove(event) {
            const oldStage = event.from.getAttribute('data-cid');
            const newStage = event.to.getAttribute('data-cid');
            const newIndex = event.newIndex;
            const oldIndex = event.oldIndex;
            let item = this.columns[newStage][newIndex];
            item.returned = true;
            this.columns[newStage].splice(newIndex, 1);
            this.columns[oldStage] = [
                ...this.columns[oldStage].slice(0, oldIndex),
                item,
                ...this.columns[oldStage].slice(oldIndex)
            ];
            setTimeout(() => {
                if (typeof this.columns[oldStage][oldIndex] !== 'undefined')
                    this.columns[oldStage][oldIndex].returned = false
            }, 100);
            return item;
        },
        //TODO:Старый костыль
        findItemById(id) {
            let result = false;
            Object.keys(this.columns).some((stage) => { 
                const index = this.columns[stage].findIndex(item => item.id === id);
                if (index > -1) {
                    result = {
                        index: index,
                        stage: stage,
                        item: this.columns[stage][index]
                    };
                    return true;
                }
                return false;
            });

            return result;
        },
        checkResponsible(lead) {
            if (this.hasRole('Manager') || this.hasRole('Admin'))
                return true;
            if (lead.stage_id == 0)
                return true;

            const user = this.$page.props?.auth?.user;
            if (!user)
                return false;
            
            if (!lead.responsible)
                return false;
            return (user?.id == lead.responsible?.id);
        },
        newItem(item) {
            item['phones'] = item['phones_json'];
            this.columns[item.stage_id].unshift(item);
        },
        editItem(item) {
            item['phones'] = item['phones_json'];
            const originalItem = this.findItemById(item.id);
            if (originalItem) {
                const new_stage_id = item.stage_id;
                this.columns[originalItem.stage].splice(originalItem.index, 1);
                if (this.checkResponsible(item)) {
                    if (!Array.isArray(this.columns[new_stage_id]))
                        this.columns[new_stage_id] = [];
                    this.columns[new_stage_id].unshift(item);
                }
            }
            else {
                this.newItem(item);
            }
        },
        deleteItem(item) {
            const originalItem = this.findItemById(item.id);
            if (originalItem) {
                this.columns[originalItem.stage].splice(originalItem.index, 1);
            }
        },
        mobileSearchHandle(closeCallback) {
            this.filterData.search = this.drawerSearchValue;
            closeCallback();
        },
        mobileSearchClear(closeCallback) {
            this.drawerSearchValue = null;
            this.filterData.search = null;
            closeCallback();
        },
        onFilter(filter) {
            if (filter) {
                this.fetch(filter);
            }
            else
                router.get(route('lead.index'));
        }
    },
    computed: {
        filteredStages() {
            const stages = [...this.stages];
            stages.splice(this.activeColumn, 1);
            return stages;
        }
    },
    watch: {
        'filterData.search': function(val) {
            this.fetch();
        }
    },
    created() {
        Echo.private('App.Models.Lead')
            .listen('.LeadCreated', (e) => {
                const lead = e.model;
                if (this.checkResponsible(lead))
                    this.newItem(lead);
            })
            .listen('.LeadUpdated', (e) => {
                const lead = e.model;
                this.editItem(lead);
            })
            .listen('.LeadDeleted', (e) => {
                const lead = e.model;
                this.deleteItem(lead);
            });
    },
    destroyed() {
        Echo.leave('App.Models.Lead');
    },
}
</script>

<style>
.non-draggable {
  cursor: not-allowed;
  /*opacity: 0.6;*/
}
</style>