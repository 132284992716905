<template>
    <Head :title="$t('update')"/>

    <Toolbar class="mb-4 !border-none">
        <template #start id="tollbar-start">
            <div class="font-medium text-xl">{{ company.name }}</div>
        </template>

        <template #end>
            <Button :label="$t('save')" @click.prevent="form.post(route('company.update', company.id))"></Button>
        </template>
    </Toolbar>

    <Fluid>
        <div class="flex mt-8">
            <div class="card flex flex-col gap-4 w-full">

                <div class="grid grid-flow-row md:grid-flow-col md:grid-cols-2 gap-y-7 gap-x-4">
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="name" v-model="form.name" :invalid="errors.name ? true : false" autocomplete="off"/>
                            <label for="name">{{ $t('v_companies.name') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.name }}</small>
                    </div>
                    <div class="gap-2 w-full">
                        <FloatLabel class="w-full">
                            <InputText id="required_workdays" :invalid="errors.required_workdays" v-model="form.required_workdays" autocomplete="off"/>
                            <label for="required_workdays">{{ $t('v_companies.required_workdays') }}</label>
                        </FloatLabel>
                        <small class="text-red-500">{{ errors.required_workdays }}</small>
                    </div>
                    <div class="gap-2 w-full md:row-span-2 content-start">
                        <div class="flex flex-col gap-6 items-center justify-center h-full">
                            <FileInput v-model="form.logo" v-model:uploadedImage="company.logo"></FileInput>
                        </div>
                    </div>
                </div>

                <h5 class="mb-2">{{ $t('v_companies.contact_person') }}</h5>
                <Repeater class="grid grid-flow-row gap-7" name="v_contacts" v-model="form.v_contacts" :errors="form.errors">
                    <template v-slot="{ item, repeaterUpdate, index, error }">
                        <FloatLabel class="w-full">
                            <InputText id="required_workdays" v-model="item['name']" :repeater-index="index" :repeater-update="repeaterUpdate" autocomplete="off"/>
                            <label for="required_workdays">{{ $t('name') }}</label>
                        </FloatLabel>
                        <FloatLabel class="w-full">
                            <InputText id="required_workdays" v-model="item['phone']" :repeater-index="index" :repeater-update="repeaterUpdate" autocomplete="off"/>
                            <label for="required_workdays">{{ $t('phone') }}</label>
                        </FloatLabel>
                    </template>
                </Repeater>

                <h5 class="mb-2">{{ $t('v_companies.parsing') }}</h5>
                <div class="grid grid-flow-row gap-y-7 gap-x-4 md:grid-cols-2">
                    <TextInput :label="$t('v_companies.parsing_keywords')" v-model="form.parsing_keywords" :errors="errors.parsing_keywords"/>
                    <TextInput :label="$t('v_companies.parsing_method')" v-model="form.parsing_method" :errors="errors.parsing_method"/>
                </div>

            </div>
        </div>
    </Fluid>
</template>

<script>
import { useForm, Head } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';

import PrimeLayout from '../../Layouts/PrimeLayout.vue';
import Alert from '../../Components/Alert.vue';
import Repeater from '../../Components/Form/Repeater.vue';
import FileInput from '../../Components/Form/FileInput.vue';

import { TextInput } from "../../Components/PrimeForm";

export default {
    layout: PrimeLayout,
    components: {
        Head,
        Alert,
        Repeater,
        FileInput,
        TextInput
    },
    props: {
        errors: Object,
        company: Object
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        const form = useForm({
            name: props.company.name,
            logo: null,
            v_contacts: props.company.v_contacts,
            required_workdays: props.company.required_workdays,
            parsing_keywords: props.company.parsing_keywords,
            parsing_method: props.company.parsing_method,
            _method: 'PUT'
        });

        return { form, hasRole, hasPermission };
    },
}
</script>