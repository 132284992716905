<template>
    <Drawer v-model:visible="visible" :blockScroll="true" :header="$t('filter')" position="right" class="!w-full md:!w-1/2 lg:!w-1/2 xl:!w-[34rem]">
        <div class="card !p-2 flex flex-col flex-wrap">
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('leads.recruiter') }}</span>
                <MultiSelect v-model="filters.responsibles" :options="responsibles" optionValue="id" optionLabel="full_name" :placeholder="$t('Any')">
                    <template #option="slotProps">
                        {{ slotProps.option?.full_name }}
                    </template>
                </MultiSelect>
            </div>
        </div>
        
        <template #footer>
            <div class="flex items-center gap-2">
                <Button :label="$t('apply')" class="flex-auto" @click.stop="onFilter"></Button>
                <Button :label="$t('clear')" class="flex-auto" outlined @click.stop="clearFilter"></Button>
            </div>
        </template>
    </Drawer>
</template>

<script>
import { ref } from "vue";
import { usePermissions } from '../Composables/usePermissions';

export default {
    components: {},
    props: {
        active: {
            type: Boolean,
            required: true
        },
        filterData: {
            type: Object,
            required: false,
            default: {}
        },
        responsibles: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        return { hasRole, hasPermission };
    },
    data() {
        return {
            //filters: null,
            selectedResponsibles: null
        };
    },
    created() {
        //this.filters = this.queryToFilter(this.filterData);
    },
    methods: {
        onFilter() {
            const filter = this.filterToQuery(this.filters);
            this.$emit('filter', filter);
            this.visible = false;
        },
        onPage(event) {
            this.$emit('page', event);
        },
        onSort(event) {
            this.$emit('sort', event);
        },
        clearFilter() {
            this.$emit('filter', null);
        },
        filterToQuery(filters) {
            //filters = _.cloneDeep(filters);
            return filters;
        },
        queryToFilter(filters) {
            //filters = _.cloneDeep(filters);
            if (filters) {
                for (let [key, value] of Object.entries(filters)) {
                    filters[key] = this.convertFilterValue(value);
                }
            }
            return filters;
        },
        convertFilterValue(value) {
            //Для множественных значений простого фильтра
            if (Array.isArray(value)) {
                let result = [];
                value.forEach((single) => {
                    single = this.convertFilterValue(single);
                    result.push(single);
                });
                return result;
            }

            if (value == 'true')
                return true;
            if (value == 'false')
                return false;

            if (typeof value == 'string') {
                value = value.replace('<=', '');
                value = value.replace('>=', '');
            }

            const parsedNumber = parseFloat(value);
            if (!isNaN(parsedNumber))
                return parsedNumber;
            return value;
        }
    },
    computed: {
        visible: {
            get() {
                return this.active;
            },
            set(val) {
                this.$emit('update:active', val);
            }
        },
        filters: {
            get() {
                return this.queryToFilter(this.filterData);
            },
            set(val) {
                this.$emit('update:filterData', val);
            }
        }
    },
    watch: {
        
    }
}
</script>