<template>
    <Head :title="$t('Company')"/>
    <ConfirmPopup></ConfirmPopup>

    <Toolbar class="mb-4 !border-none">
        <template #start id="tollbar-start">
            <div class="font-medium text-xl">{{ $t('Company') }}</div>
        </template>

        <template #center>
            <IconField>
                <InputIcon>
                    <i class="pi pi-search" />
                </InputIcon>
                <InputText :placeholder="$t('search')" v-model="search"/>
            </IconField>
        </template>

        <template #end>
            <Button as="router-link" :label="$t('create')" :to="route('company.create')"></Button>
        </template>
    </Toolbar>

    <div class="card">
        <DataView :value="companies" :layout="layout" :rows="50" :paginator="true">
            <!-- <template #header>
                <div class="flex justify-end">
                    <SelectButton v-model="layout" :options="options" :allowEmpty="false">
                        <template #option="{ option }">
                            <i :class="[option === 'list' ? 'pi pi-bars' : 'pi pi-table']" />
                        </template>
                    </SelectButton>
                </div>
            </template> -->

            <template #list="slotProps">
                <div class="flex flex-col">
                    <div v-for="(item, index) in slotProps.items" :key="item.id">
                        <div class="flex flex-row sm:flex-row sm:items-center p-4 gap-4" :class="{ 'border-t border-surface': index !== 0 }">
                            <div class="md:w-10 relative">
                                <img class="block xl:block mx-auto rounded w-full" :src="item.logo" :alt="item.name" />
                            </div>
                            <div class="flex flex-col md:flex-row justify-between md:items-center flex-1 gap-6">
                                <div class="flex flex-row md:flex-col justify-between items-start gap-2">
                                    <div class="text-lg font-medium">{{ item.name }}</div>
                                </div>
                                <div class="flex flex-col md:items-end gap-8">
                                    <div class="flex flex-row-reverse md:flex-row gap-2">
                                        <Button as="router-link" icon="pi pi-pencil" outlined rounded :to="route('company.edit', item.id)"></Button>
                                        <Button icon="pi pi-trash" outlined rounded severity="danger" @click="destroy($event, item.id)"></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </DataView>
    </div>
</template>

<script>
import { Link, Head, router } from "@inertiajs/vue3";
import PrimeLayout from "../../Layouts/PrimeLayout.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useToast } from "vue-toastification";
import { useConfirm } from 'primevue/useconfirm';
import { trans } from 'laravel-vue-i18n';

export default {
    layout: PrimeLayout,
    components: {
        Link, Head
    },
    props: {
        companies: Object,
        searchName: {
            type: String,
            required: false,
            default: null
        }
    },
    setup() {
        const { hasRole, hasPermission } = usePermissions();
        const confirmPopup = useConfirm();
        return { hasRole, hasPermission, confirmPopup };
    },
    data() {
        return {
            options: ['list', 'grid'],
            layout: 'list',
            search: this.searchName
        }
    },
    methods: {
        destroy(event, id) {
            this.confirmPopup.require({
                target: event.currentTarget,
                message: 'Подтвердить удаление?',
                icon: 'pi pi-info-circle',
                rejectProps: {
                    label: 'Отменить',
                    severity: 'secondary',
                    outlined: true
                },
                acceptProps: {
                    label: 'Да',
                    severity: 'danger'
                },
                accept: () => {
                    router.delete(route('company.destroy', id));
                    this.toast.success(trans('deleted'));
                }
            });
        }
    },
    watch: {
        search(val) {
            let query = {};
            if (val) {
                query.search = val;
            }
            router.get(route('company.index'), query, {
                preserveState: true
            });
        }
    }
}
</script>