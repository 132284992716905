<template>
    <div>
        <Head :title="$t('edit')"/>
        <Toolbar class="mb-4 !border-none" pt:start:class="!grow">
            <template #start id="tollbar-start">
                <div class="flex flex-row w-full items-center">
                    <div class="flex items-center gap-2 mr-2">
                        <i @click.prevent="form.active = !form.active"  v-tooltip="$t('vacancies.active')" class="pi !text-[1.35rem] cursor-pointer" :class="{ 'pi-check-circle text-green-500 ': form.active, 'pi-times-circle text-red-500': !form.active }"></i>
                        <span @click.prevent="form.hot = !form.hot" v-tooltip="$t('vacancies.hot')" class="cursor-pointer material-symbols-outlined block float-left mt-[-2px]" :class="{ 'text-rose-400': form.hot, 'text-[#64748b]': !form.hot }" translate="no">mode_heat</span>
                    </div>
                    <InputText :pt="{content: { class: '!bg-transparent !py-3' }, header: {class: '!rounded'}}" :value="title" @change="form.title = $event.target.value" fluid/>
                </div>
            </template>

            <template #end>
                <Button :label="$t('edit')" :disabled="form.processing" @click.prevent="submitForm"></Button>
            </template>
        </Toolbar>

        <Fluid>
            <div class="flex mt-8">
                <Tabs value="0" class="w-full" scrollable>
                    <TabList class="rounded-md">
                        <Tab value="0" as="div" class="flex items-center gap-2">
                            <span class="material-symbols-outlined" translate="no">home</span>
                            <span>{{ $t('vacancies.parameters') }}</span>
                        </Tab>
                        <Tab value="1" as="div" class="flex items-center gap-2">
                            <span class="material-symbols-outlined" translate="no">description</span>
                            <span>{{ $t('vacancies.descriptions') }}</span>
                        </Tab>
                        <Tab value="2">
                            <div class="flex items-center gap-2">
                                <span class="material-symbols-outlined" translate="no">imagesmode</span>
                                <span>{{ $t('vacancies.photo') }}</span>
                                <Badge :value="vacancy.files ? Object.keys(vacancy.files).length : 0" class="self-end" />
                            </div>
                        </Tab>
                    </TabList>

                    <TabPanels class="!bg-transparent !p-0">
                        <TabPanel value="0">
                            <div class="mt-8">
                                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                                    <div class="!p-4 !m-0 card">
                                        <div class="grid grid-cols-1 gap-y-7">
                                            <div class="flex justify-between">
                                                <div class="text-xl mr-2">
                                                    {{ $t('vacancies.requirements') }}
                                                </div>
                                                <div class="flex flex-wrap lg:gap-1">
                                                    <span v-tooltip.top="$t('vacancies.udt')" class="material-symbols-outlined cursor-pointer" :class="form.udt ? 'text-violet-600' : 'text-[#64748b]'" @click.prevent="form.udt = !form.udt" translate="no">forklift</span>
                                                    <span v-tooltip.top="$t('vacancies.medical_book')" class="material-symbols-outlined cursor-pointer" :class="form.medical_book ? 'text-red-600' : 'text-[#64748b]'" @click.prevent="form.medical_book = !form.medical_book" translate="no">medical_information</span>
                                                    <span v-tooltip.top="$t('vacancies.experience')" class="material-symbols-outlined cursor-pointer" :class="form.experience ? 'text-sky-600' : 'text-[#64748b]'" @click.prevent="form.experience = !form.experience" translate="no">work_history</span>
                                                    <!-- <span v-tooltip.top="$t('vacancies.male')" class="material-symbols-outlined cursor-pointer" :class="form.allowed_genders.includes(1) ? 'text-blue-600' : 'text-[#64748b]'" @click.prevent="toggleGender(1)">male</span>
                                                    <span v-tooltip.top="$t('vacancies.female')" class="material-symbols-outlined cursor-pointer" :class="form.allowed_genders.includes(2) ? 'text-pink-600' : 'text-[#64748b]'" @click.prevent="toggleGender(2)">female</span>
                                                    <span v-tooltip.top="$t('vacancies.both')" class="material-symbols-outlined cursor-pointer" :class="form.allowed_genders.includes(3) ? 'text-indigo-600' : 'text-[#64748b]'" @click.prevent="toggleGender(3)">family_restroom</span> -->
                                                </div>
                                            </div>
                                            <div class="flex gap-4">
                                                <InputWrap :errors="errors.allowed_genders">
                                                    <template v-slot="{ id, invalid }">
                                                        <SelectButton v-model="form.allowed_genders" multiple :options="genders" optionLabel="icon" optionValue="value" dataKey="value" aria-labelledby="custom" :invalid="invalid" class="justify-center w-full md:justify-start md:w-auto">
                                                            <template #option="slotProps">
                                                                <span :class="slotProps.option.class" translate="no">{{ slotProps.option.icon }}</span>
                                                            </template>
                                                        </SelectButton>
                                                    </template>
                                                </InputWrap>
                                                <TextInput :label="$t('vacancies.number_of_workplaces_short')" v-model="form.number_of_workplaces" :errors="errors.number_of_workplaces" />
                                            </div>

                                            <div class="flex gap-4">
                                                <TextInput :label="$t('vacancies.age')+' От'" v-model="form.min_age" :errors="errors.min_age" />
                                                <TextInput :label="$t('vacancies.age')+' До'" v-model="form.max_age" :errors="errors.max_age" />
                                            </div>
                                            <TextInput :label="$t('vacancies.position')" v-model="form.position" :errors="errors.position" />
                                            <InputWrap :label="$t('Citizenship')" :errors="errors.citizenships">
                                                <template v-slot="{id, invalid}">
                                                    <MultiSelect 
                                                        :inputId="id"
                                                        :invalid="invalid"
                                                        v-model="form.citizenships" 
                                                        display="chip" 
                                                        :options="citizenships" 
                                                        optionLabel="name"
                                                        optionValue="id" 
                                                        filter 
                                                    />
                                                </template>
                                            </InputWrap>
                                            <Html v-model="form.special_requirements" :label="$t('vacancies.special_requirements')" :errors="errors.special_requirements"/>
                                        </div>
                                    </div>
                                    <div class="!p-4 !m-0 card">
                                        <div class="grid grid-cols-1 gap-y-7">
                                            <div class="text-xl">
                                                {{ $t('vacancies.workplace') }}
                                            </div>
                                            <InputWrap :label="$t('Facility')" :errors="errors.facility_id">
                                                <template v-slot="{id, invalid}">
                                                    <Select :inputId="id" :invalid="invalid" v-model="form.facility_id" :options="facilities" optionLabel="name" optionValue="id" />
                                                </template>
                                            </InputWrap>
                                            <InputWrap :label="$t('Company')" :errors="errors.company_id">
                                                <template v-slot="{id, invalid}">
                                                    <Select :inputId="id" :invalid="invalid" v-model="form.company_id" :options="companies" optionLabel="name" optionValue="id" />
                                                </template>
                                            </InputWrap>
                                            <InputWrap :label="$t('vacancies.work_city')" :errors="errors.work_city_id">
                                                <template v-slot="{id, invalid}">
                                                    <AutoComplete  
                                                        :invalid="invalid"
                                                        :inputId="id"
                                                        v-model="this.form.work_city" 
                                                        :suggestions="cities" 
                                                        optionLabel="full_name" 
                                                        optionValue="id" 
                                                        class=""
                                                        forceSelection
                                                        :loading="workCitySelectLoading"
                                                        @complete="fetchCities($event, 'workCitySelectLoading')"  
                                                    />
                                                </template>
                                            </InputWrap>
                                            <InputWrap :label="$t('vacancies.documents_city')" :errors="errors.documents_city_id">
                                                <template v-slot="{id, invalid}">
                                                    <AutoComplete  
                                                        :invalid="invalid"
                                                        :inputId="id"
                                                        v-model="this.form.documents_city" 
                                                        :suggestions="cities" 
                                                        optionLabel="full_name"
                                                        optionValue="id" 
                                                        class=""
                                                        forceSelection
                                                        :loading="documentCitySelectLoading"
                                                        @complete="fetchCities($event, 'documentCitySelectLoading')"  
                                                    />
                                                </template>
                                            </InputWrap>
                                            <InputWrap :label="$t('vacancies.closest_city')" :errors="errors.closest_city_id">
                                                <template v-slot="{id, invalid}">
                                                    <AutoComplete  
                                                        :invalid="invalid"
                                                        :inputId="id"
                                                        v-model="this.form.closest_city" 
                                                        :suggestions="cities" 
                                                        optionLabel="full_name" 
                                                        optionValue="id" 
                                                        class=""
                                                        forceSelection
                                                        :loading="closestCitySelectLoading"
                                                        @complete="fetchCities($event, 'closestCitySelectLoading')"  
                                                    />
                                                </template>
                                            </InputWrap>
                                            <TextInput :label="$t('vacancies.closest_city_km')" v-model="form.closest_city_km" :errors="errors.closest_city_km" />
                                        </div>
                                    </div>
                                    <div>
                                        <div class="!p-4 card !mb-4 grid grid-cols-1 gap-y-7">
                                            <div class="text-xl">
                                                {{ $t('vacancies.salary') }}
                                            </div>
                                            <div class="grid grid-cols-2 gap-x-4">
                                                <div>
                                                    <TextInput :label="$t('vacancies.base_rate_short')" v-model="form.base_rate" :errors="errors.base_rate" />
                                                </div>
                                                <div>
                                                    <TextInput :label="$t('vacancies.young_rate_short')" v-model="form.young_rate" :errors="errors.young_rate" />
                                                </div>
                                            </div>
                                            <div class="grid grid-cols-2 gap-x-4">
                                                <div>
                                                    <TextInput :label="$t('vacancies.student_rate_short')" v-model="form.student_rate" :errors="errors.student_rate" />
                                                </div>
                                                <div>
                                                    <TextInput :label="$t('vacancies.avg_salary')" v-model="form.avg_salary" :errors="errors.avg_salary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="!p-4 card grid grid-cols-1 gap-y-7">
                                            <div class="text-xl">
                                                {{ $t('vacancies.conditions') }}
                                            </div>
                                            <InputWrap :label="$t('vacancies.contract_type')" :errors="errors.contract_type">
                                                <template v-slot="{id, invalid}">
                                                    <Select :inputId="id" :invalid="invalid" v-model="form.contract_type" :options="contract_types" optionLabel="name" optionValue="id"/>
                                                </template>
                                            </InputWrap>
                                            <InputWrap :label="$t('vacancies.work_type')" :errors="errors.preferred_job_id">
                                                <template v-slot="{id, invalid}">
                                                    <Select :inputId="id" :invalid="invalid" v-model="form.preferred_job_id" :options="preferred_jobs" optionLabel="name" optionValue="id"/>
                                                </template>
                                            </InputWrap>
                                            <Html v-model="form.conditions" :label="$t('vacancies.conditions_description')" :errors="errors.conditions"/>
                                        </div>
                                    </div>
                                    <div class="!p-4 card !m-0">
                                        <div class="grid grid-cols-1 gap-y-7">
                                            <div class="flex justify-between">
                                                <div class="text-xl mr-2">
                                                    {{ $t('vacancies.housing') }}
                                                </div>
                                                <div class="flex flex-wrap lg:gap-1">
                                                    <span v-tooltip.left="$t('vacancies.housing_compensation')" class="material-symbols-outlined cursor-pointer" :class="form.housing_compensation ? 'text-[#b77305]' : 'text-[#64748b]'" @click.prevent="form.housing_compensation = !form.housing_compensation" translate="no">real_estate_agent</span>
                                                </div>
                                            </div>
                                            <InputWrap :label="$t('vacancies.housing_type')" :errors="errors.housing_type">
                                                <template v-slot="{id, invalid}">
                                                    <MultiSelect 
                                                        :inputId="id"
                                                        :invalid="invalid"
                                                        v-model="form.housing_type" 
                                                        display="chip" 
                                                        :options="housing_types" 
                                                        optionLabel="name"
                                                        optionValue="id" 
                                                    />
                                                </template>
                                            </InputWrap>
                                            <div class="grid grid-cols-2 gap-x-4">
                                                <div>
                                                    <TextInput :label="$t('vacancies.housing_cost')" v-model="form.housing_cost" :errors="errors.housing_cost" />
                                                </div>
                                                <div>
                                                    <TextInput :label="$t('vacancies.student_housing_cost')" v-model="form.student_housing_cost" :errors="errors.student_housing_cost" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="!p-4 card !m-0">
                                        <div class="grid grid-cols-1 gap-y-7">
                                            <div class="flex justify-between">
                                                <div class="text-xl mr-2">
                                                    {{ $t('vacancies.work_hours') }}
                                                </div>
                                                <div class="flex flex-wrap lg:gap-1">
                                                    <span v-tooltip.left="$t('vacancies.night_shifts')" class="material-symbols-outlined cursor-pointer" :class="form.night_shifts ? 'text-[#d1de43]' : 'text-[#64748b]'" @click.prevent="form.night_shifts = !form.night_shifts" translate="no">dark_mode</span>
                                                </div>
                                            </div>
                                            <Html v-model="form.work_schedule_text" :label="$t('vacancies.work_schedule_description')" :errors="errors.work_schedule_text"/>
                                        </div>
                                    </div>
                                    <div class="!p-4 card !m-0">
                                        <div class="grid grid-cols-1 gap-y-7">
                                            <div class="text-xl">
                                                {{ $t('vacancies.parsing') }}
                                            </div>
                                            <div>
                                                <TextInput :label="$t('vacancies.parsing_url')" v-model="form.parsing_url" :errors="errors.parsing_url" />
                                            </div>
                                            <div>
                                                <TextInput :label="$t('vacancies.parsing_id')" v-model="form.parsing_id" :errors="errors.parsing_id" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <Tabpanel value="1">
                            <div class="mt-8">
                                <div class="!p-4 card !mb-4 !pt-8">
                                    <div>
                                        <Html v-model="form.description" :label="$t('vacancies.description')" :errors="errors.description"/>
                                    </div>
                                </div>
                                <div class="!p-4 card !mb-4 !pt-8">
                                    <div>
                                        <Html v-model="form.recruiter_description" :label="$t('vacancies.recruiter_description')" :errors="errors.recruiter_description"/>
                                    </div>
                                </div>
                            </div>
                        </Tabpanel>
                        <Tabpanel value="2">
                            <div class="mt-8">
                                <Dropzone :preloaded="preloadedFiles" v-model:files="form.files" v-model:remove="form.files_remove"></Dropzone>
                            </div>
                        </Tabpanel>
                    </TabPanels>
                </Tabs>
            </div>
        </Fluid>
    </div>
</template>

<script>
import { Head, useForm, Link } from '@inertiajs/vue3'
import { usePermissions } from '../../Composables/usePermissions';
import PrimeLayout from '../../Layouts/PrimeLayout.vue';
import Dropzone from '../../Components/PrimeForm/Dropzone.vue';
import { TextInput, Date, PhoneInput, InputWrap, Html } from "../../Components/PrimeForm";

export default {
    layout: PrimeLayout,
    components: {
        Head, Link, TextInput, Date, PhoneInput, InputWrap, Html, Dropzone
    },
    props: {
        citizenships: Object,
        companies: Object,
        facilities: Object,
        vacancy: Object,
        preferred_jobs: Array,
        errors: Object
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();
        const form = useForm({
            title: props.vacancy.title ?? null,
            active: props.vacancy.active ?? false,
            featured: props.vacancy.featured ?? false,
            hot: props.vacancy.hot ?? false,
            medical_book: props.vacancy.medical_book?? false,
            udt: props.vacancy.udt ?? false,
            experience: props.vacancy.experience ?? false,
            min_age: props.vacancy.min_age ?? null,
            max_age: props.vacancy.max_age ?? null,
            allowed_genders: props.vacancy.allowed_genders?.map(Number) ?? null,
            position: props.vacancy.position ?? null,
            number_of_workplaces: props.vacancy.number_of_workplaces ?? null,
            citizenships: props.vacancy.citizenships ?? null,
            special_requirements: props.vacancy.special_requirements ?? null,
            facility_id: props.vacancy.facility?.id ?? null,
            company_id: props.vacancy.company?.id ?? null,
            work_city: props.vacancy.work_city ?? null,
            closest_city:  props.vacancy.closest_city ?? null,
            closest_city_km: props.vacancy.closest_city_km ?? null,
            documents_city: props.vacancy.documents_city ?? null,
            base_rate: props.vacancy.base_rate ?? null,
            young_rate: props.vacancy.young_rate ?? null,
            student_rate: props.vacancy.student_rate ?? null,
            avg_salary: props.vacancy.avg_salary ?? null,
            housing_compensation: props.vacancy.housing_compensation ?? false,
            housing_type: props.vacancy.housing_type.map(Number) ?? null,
            housing_cost: props.vacancy.housing_cost ?? null,
            student_housing_cost: props.vacancy.student_housing_cost ?? null,
            contract_type: props.vacancy.contract_type ?? [],
            conditions: props.vacancy.conditions ?? null,
            night_shifts: props.vacancy.night_shifts ?? false,
            work_schedule_text: props.vacancy.work_schedule_text ?? null,
            description: props.vacancy.description ?? null,
            recruiter_description: props.vacancy.recruiter_description ?? null,
            required_workdays: props.vacancy.required_workdays ?? null,
            preferred_job_id: props.vacancy.preferred_job?.id ?? null,
            files: [],
            files_remove: [],
            parsing_id: props.vacancy.parsing_id ?? null,
            parsing_url: props.vacancy.parsing_url ?? null,
            _method: 'PUT'
        });

        const genders = [
            {
                icon: 'male',
                class: 'material-symbols-outlined text-blue-600',
                value: 1
            },
            {
                icon: 'female',
                class: 'material-symbols-outlined text-pink-600',
                value: 2
            },
            {
                icon: 'family_restroom',
                class: 'material-symbols-outlined text-indigo-600',
                value: 3
            }
        ];

        return { form, hasRole, hasPermission, genders };
    },
    data() {
        return {
            housing_types: [{id: 1, name: 'Свое'},{id: 2, name: 'Предоставляемое'}],
            contract_types: [{ name: 'Umowa zlecenia', id: 1 }, { name: 'Umowa o pracę', id: 2 }],
            cities: [],
            documentCitySelectLoading: false,
            workCitySelectLoading: false,
            closestCitySelectLoading: false,
            preloadedFiles: this.vacancy?.files,
        }
    },
    methods: {
        toggleGender(gender) {
            const index = this.form.allowed_genders.indexOf(gender);
            if (index !== -1) {
                this.form.allowed_genders.splice(index, 1);
            } else {
                this.form.allowed_genders.push(gender);
            }
        },
        fetchCities(event, loaderName) {
            this[loaderName] = true;

            axios
                .post('/ajax/select2', {
                    q: event.query,
                    attribute: 'full_name',
                    related_model: 'App\\Models\\City',
                    resource: 'App\\Http\\Resources\\CityResource',
                })
                .then(response => {
                    this.cities = response.data.data;
                    this[loaderName] = false;
                });
        },
        submitForm() {
            this.form
                .transform((data) => (this.prepareItem(data)))
                .post(route('vacancy.update', this.vacancy.id));
        },
        prepareItem(data) {
            
            if (data.title === null || data.title === '') {
                data.title = this.titleGenerated;
            }

            data.work_city_id = data.work_city?.id ?? null;
            data.closest_city_id = data.closest_city?.id ?? null;
            data.documents_city_id = data.documents_city?.id ?? null;
            
            return data;
        },
        getParsingKey(url) {
            const regex = /^https:\/\/gremi\.planfix\.com\/.*?[?&]handbook=23346.*?[?&]key=(\d+)/;
            const match = url.match(regex);
            return match ? match[1] : null;
        }
    },
    watch: {
        'form.parsing_url': function(val) {
            this.form.parsing_id = this.getParsingKey(val);
        }
    },
    computed: {
        title: {
            get: function () {
                return !this.form.title ? this.titleGenerated : this.form.title;
            }
        },
        titleGenerated: function () {
            return `${this.form.position ?? ''} ${this.form.work_city?.name ?? ''}`.trim();
        },
    },
}
</script>