<template>
    <div>
        <Head :title="$t('Vacancies')"/>
        <ConfirmPopup v-if="viewType == 'cards'"></ConfirmPopup>
        <ConfirmDialog v-else></ConfirmDialog>

        <Toolbar class="mb-4 !border-none">
            <template #start id="tollbar-start">
                <div class="font-medium text-xl">{{ $t('Vacancies') }}</div>
                <SelectButton v-if="!mobile" v-model="viewType" :options="views" optionLabel="value" optionValue="value" :allowEmpty="false" aria-labelledby="custom" class="ms-4">
                    <template #option="slotProps">
                        <i class="pi" :class="slotProps.option.icon" v-tooltip.top="$t(slotProps.option.value)"></i>
                    </template>
                </SelectButton>
                <ToggleButton v-if="viewType == 'table' && hasPermission('edit-vacancy')" v-model="editMode" pt:label:class="hidden" onIcon="pi pi-lock-open" offIcon="pi pi-lock" class="ms-2" />
                <Button icon="pi pi-search" severity="secondary" class="md:!hidden" text rounded @click="searchDrawer = !searchDrawer"/>
            </template>

            <template #center>
                <IconField class="!hidden md:!block" v-if="viewType == 'cards'">
                    <InputIcon>
                        <i class="pi pi-search" />
                    </InputIcon>
                    <InputText :placeholder="$t('search')" v-model="filterData.search"/>
                </IconField>
            </template>

            <template #end>
                <Button v-if="viewType == 'cards'" icon="pi pi-filter" severity="secondary" class="me-2" @click="filterDrawer = !filterDrawer" outlined/>
                <Link v-if="viewType != 'cards' && hasPermission('create-vacancy')" :href="route('vacancy-parser')"><Button icon="pi pi-sparkles" severity="secondary" class="me-2"  outlined v-tooltip.top="'Парсер'"/></Link>
                <Button v-if="hasPermission('create-vacancy')" as="router-link" :label="$t('create')" :to="route('vacancy.create')"></Button>
            </template>
        </Toolbar>

        

        <div v-if="viewType == 'cards'" class="card !bg-[var(--surface-ground)] !p-0">
            <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                <VacancyCard v-for="vacancy in vacancies.data" :key="vacancy.id" :vacancy="vacancy"  @destroy="cardDestroy"></VacancyCard>
            </div>
            <Paginator class="mt-4" pt:root:class="!bg-[var(--surface-ground)]" :rows="vacancies.meta.per_page" :totalRecords="vacancies.meta.total" @page="switchPage" :alwaysShow="false"></Paginator>
            <VacancyFilter 
                v-model:active="filterDrawer" 
                @filter="onTableFilter"
                @sort="onTableSort"
                :filterData="filterData"
                :filterLogic="filterLogic" 
                :filter-sliders="filterSliders"
                :vacancies="vacancies" 
                :companies="companies" 
                :facilities="facilities" 
                :citizenships="citizenships"
                :genders="genders"
                :requirements="requirements"
                :housingTypes="housingTypes"
                :contractTypes="contractTypes"
            />
        </div>

        <VacancyTable 
            v-else
            @page="switchPage" 
            @filter="onTableFilter"
            @sort="onTableSort"
            @destroy="tableDestroy"
            :filterData="filterData"
            :filterLogic="filterLogic" 
            :sortLogic="sortLogic"
            :vacancies="vacancies" 
            :companies="companies" 
            :facilities="facilities" 
            :citizenships="citizenships"
            :editMode="editMode"
            :genderIcons="genderIcons"
            :genders="genders"
            :requirements="requirements"
            :housingTypes="housingTypes"
            :contractTypes="contractTypes"
        />

        <Drawer v-model:visible="searchDrawer" position="top" style="height: auto">
            <template #container="{ closeCallback }">
                <div class="flex flex-row items-center gap-1 p-4">
                    <div class="grow">
                        <InputText class="!border-none !shadow-none" :placeholder="$t('search')" v-model="drawerSearchValue" fluid size="large" @keyup.enter="mobileSearchHandle(closeCallback)"/>
                    </div>
                    <div>
                        <Button v-if="drawerSearchValue" type="button" severity="secondary" @click="mobileSearchClear(closeCallback)" icon="pi pi-times" text size="large"></Button>
                        <Button type="button" severity="secondary" @click="mobileSearchHandle(closeCallback)" icon="pi pi-search" text size="large"></Button>
                    </div>
                </div>
            </template>
        </Drawer>
    </div>
</template>

<script>
import { ref } from 'vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { Link, Head, router } from "@inertiajs/vue3";
import PrimeFullHeightLayout from "../../Layouts/PrimeFullHeightLayout.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useConfirm } from 'primevue/useconfirm';
import VacancyCard from '../../Partials/VacancyCard.vue';
import VacancyTable from '../../Partials/VacancyTable.vue';
import VacancyFilter from '../../Components/VacancyFilter.vue';
import { trans } from 'laravel-vue-i18n';

export default {
    layout: PrimeFullHeightLayout,
    components: {
        Link, Head, VacancyCard, VacancyTable, VacancyFilter
    },
    props: {
        filter: Object,
        filterLogic: Object,
        sort: String,
        vacancies: Object,
        companies: Array,
        facilities: Array,
        citizenships: Array,
        filterSliders: Object
    },
    setup(props) {
        const filterData = ref({
            search: props.filter?.search ?? null,
            active: props.filter?.active,
            title: props.filter?.title,
            number_of_workplaces: props.filter?.number_of_workplaces,
            base_rate: props.filter?.base_rate,
            student_rate: props.filter?.student_rate,
            young_rate: props.filter?.young_rate,
            max_age: props.filter?.max_age,
            company: props.filter?.company,
            facility: props.filter?.facility,
            citizenship: props.filter?.citizenship,
            housing_type: props.filter?.housing_type,
            contract_type: props.filter?.contract_type,
            allowed_genders: props.filter?.allowed_genders,
            requirements: props.filter?.requirements,
            work_city: props.filter?.work_city,
            closest_city: props.filter?.closest_city,
            closest_city_km: props.filter?.closest_city_km
        });
        const filterLogic = ref(props.filterLogic ?? {});
        const sortLogic = ref(props.sort ?? null);

        const genderIcons = {
            1: {
                icon: 'male',
                class: 'text-blue-600'
            },
            2: {
                icon: 'female',
                class: 'text-pink-600'
            },
            3: {
                icon: 'family_restroom',
                class: 'text-indigo-600 text-xl'
            }
        };

        const views = [
            {
                value: 'table',
                icon: 'pi-table',
            },
            {
                value: 'cards',
                icon: 'pi-objects-column',
            },
        ];

        const contractTypes = [
            { name: 'Umowa zlecenia', id: 1 },
            { name: 'Umowa o pracę', id: 2 }
        ];

        const housingTypes = [
            { id: 1, name: 'Свое' },
            { id: 2, name: 'Предоставляемое' }
        ];

        const genders = [
            {
                icon: 'male',
                class: 'material-symbols-outlined text-blue-600',
                value: 1
            },
            {
                icon: 'female',
                class: 'material-symbols-outlined text-pink-600',
                value: 2
            },
            {
                icon: 'family_restroom',
                class: 'material-symbols-outlined text-indigo-600',
                value: 3
            }
        ];

        const requirements = [
            {
                value: 'udt',
                icon: 'forklift',
                class: 'material-symbols-outlined text-violet-600'
            },
            {
                value: 'medical_book',
                icon: 'medical_information',
                class: 'material-symbols-outlined text-red-600'
            },
            {
                value: 'experience',
                icon: 'work_history',
                class: 'material-symbols-outlined text-sky-600'
            },
            {
                value: 'housing_compensation',
                icon: 'real_estate_agent',
                class: 'material-symbols-outlined'
            },
            {
                value: 'night_shifts',
                icon: 'dark_mode',
                class: 'material-symbols-outlined text-rose-400'
            },
        ];

        const breakpoints = useBreakpoints(breakpointsTailwind);
        const mobile = breakpoints.smallerOrEqual('md');

        let viewType = localStorage.vacanciesView ?? 'table';
        let editMode = localStorage.vacanciesEditMode == 'true' ? true : false;
        
        if (mobile.value) {
            viewType = 'cards';
            editMode = false;
        }

        viewType = ref(viewType);
        editMode = ref(editMode);

        const { hasRole, hasPermission } = usePermissions();
        const confirmPopup = useConfirm();

        return {
            hasRole,
            hasPermission,
            confirmPopup,
            filterData,
            filterLogic,
            sortLogic,
            genderIcons,
            views,
            genders,
            requirements,
            contractTypes,
            housingTypes,
            mobile,
            viewType,
            editMode
        };
    },
    data() {
        return {
            //viewType: 'table',
            //editMode: false,
            filterDrawer: false,
            searchDrawer: false,
            drawerSearchValue: this.filterData.search
        }
    },
    created() {
        this.searchStart = _.debounce((vm, text) => {
            if (text && text.length >= 3) {
                vm.queries['search'] = text;
            } else if(vm.queries['search']){
                Reflect.deleteProperty(vm.queries, 'search');
            }
        }, 1200);

        this.workplacesCntChange = _.debounce((vm, id, value) => {
            axios.post(route('set-field-value', id), {
                field_name: 'number_of_workplaces',
                value: value
            })
            .then(response => {
                    router.reload();
                this.toast.success(response.data.success, { timeout: 2000 });
            })
            .catch(error => {
                this.toast.error('Ошибка', { timeout: 2000 });
            });
        }, 500);
    },
    methods: {
        switchPage(event) {
            const page = event.page + 1;
            this.fetch(page);
        },
        onTableFilter(filter) {
            if (filter) {
                this.fetch(false, filter);
            }
            else
                router.get(this.vacancies.meta.path);
        },
        onTableSort(event) {
            const sortField = event.sortField;
            if (sortField) {
                const sortOrder = event.sortOrder == -1 ? '-' : '';
                const sort = sortOrder + sortField;
                this.sortLogic = sort;
                this.fetch(false, null, sort);
            }
        },
        fetch(page=false, filter=null, sort=null){
            let pageUrl = this.vacancies.meta.path + `?page=${this.vacancies.meta.current_page}`;
            if (page)
                pageUrl = this.vacancies.meta.path + `?page=${page}`;

            let logic = null;
            if (filter && filter.hasOwnProperty('logic')) {
                logic = filter.logic;
                filter = filter.query;
                this.filterData = filter;
                this.filterLogic = logic;
            }

            let query = {
                filter: this.makeFilterQuery(filter ?? this.filterData),
                filterLogic: logic ?? this.filterLogic,
            }

            sort = sort ?? this.sortLogic;
            if (sort) {
                query.sort = sort;
            }

            router.get(
                pageUrl, 
                query, 
                { 
                    preserveScroll: true, 
                    preserveState: true,
                    onBefore: (visit) => {this.loading = true;},
                    onFinish: (visit) => {this.loading = false;}
                }
            );
        },
        makeFilterQuery(filterValues){
            let query = {};
            Object.keys(filterValues).forEach((key)=>{
                const value = filterValues[key];
                if (value !== null && value !== undefined){
                    query[key] = filterValues[key];
                }
            });
            
            return query;
        },
        cardDestroy(event, id) {
            this.confirmPopup.require({
                target: event.currentTarget,
                message: 'Подтвердить удаление?',
                icon: 'pi pi-info-circle',
                rejectProps: {
                    label: 'Отменить',
                    severity: 'secondary',
                    outlined: true
                },
                acceptProps: {
                    label: 'Да',
                    severity: 'danger'
                },
                accept: () => {
                    router.delete(route('vacancy.destroy', id));
                }
            });
        },
        tableDestroy(id) {
            this.confirmPopup.require({
                header: 'Удаление',
                message: 'Подтвердить удаление?',
                icon: 'pi pi-info-circle',
                rejectProps: {
                    label: 'Отменить',
                    severity: 'secondary',
                    outlined: true
                },
                acceptProps: {
                    label: 'Да',
                    severity: 'danger'
                },
                accept: () => {
                    router.delete(route('vacancy.destroy', id));
                },
            });
        },
        mobileSearchHandle(closeCallback) {
            this.filterData.search = this.drawerSearchValue;
            closeCallback();
        },
        mobileSearchClear(closeCallback) {
            this.drawerSearchValue = null;
            this.filterData.search = null;
            closeCallback();
        },
    },
    watch: {
        'filterData.search': function(val) {
            this.fetch(false);
        },
        viewType(value) {
            localStorage.vacanciesView = value;
            if (value == 'cards')
                this.editMode = false;
        },
        editMode(value) {
            localStorage.vacanciesEditMode = value;
        }
    }
}
</script>